import { Component, OnInit, ViewChild } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastUiImageEditorComponent } from "ngx-tui-image-editor";
// import {base64ToFile} from 'base64-to-file';
import ImageEditor from 'tui-image-editor';
@Component({
    selector: 'app-new',
    templateUrl: './new.component.html',
    styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
    @ViewChild(ToastUiImageEditorComponent) editorComponent: ToastUiImageEditorComponent;
    constructor() { }
    imageEditor: any
    imageEditor2: any
    imgexam:any = [];
    ngOnInit() {
        this.imageEditor = new ImageEditor(document.querySelector('#tui-image-editor'), {
            includeUI: {
                loadImage: {
                    path: 'https://stickershop.line-scdn.net/stickershop/v1/product/5416176/LINEStorePC/main.png;compress=true',
                    name: 'SampleImage'
                },
                // theme: blackTheme, // or whiteTheme
                initMenu: 'filter',
                menuBarPosition: 'bottom'
            },
            cssMaxWidth: 700,
            cssMaxHeight: 500,
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70
            }
        });
        // console.log(this.editorComponent.editorInstance);
        // var options = {
        //     usageStatistics: false
        // }

        // this.imageEditor = new ImageEditor('#tui-image-editor-container', options);

        // let ele : any = document.querySelector('#tui-image-editor')
        // ele.src = imageEditor.toDataURL();
        // console.log(ele)

        // imageEditor.loadImageFromURL(imageEditor.toDataURL(), 'FilterImage').then(() => {
        //     imageEditor.addImageObject(imgUrl);
        // });
        // const instance = new ImageEditor(ele, {
        //     cssMaxWidth: 700,
        //     cssMaxHeight: 500,
        //     selectionStyle: {
        //       cornerSize: 20,
        //       rotatingPointOffset: 70
        //     }
        //   });
        //   console.log(instance)
        //   ele.srcObject = instance;
        //   console.log(ele)


    }
    getdata() {
        this.imgexam.push(this.imageEditor.toDataURL()) 
        console.log(this.imageEditor.toDataURL())
        // var options = {
        //     // usageStatistics: false
        // }
        // let ele: any = document.querySelector('#tui-image-editor')
        // this.imageEditor = new ImageEditor('#tui-image-editor-container', options);
        // ele.src = this.imageEditor.toDataURL();

        // this.imageEditor.loadImageFromURL(this.imageEditor.toDataURL(), 'FilterImage').then(() => {
        //     // imageEditor.addImageObject(imgUrl);
        // });
    }
    //   ===========
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(event, this.croppedImage);
        //     console.log(event, base64ToFile(event.base64));
    }

    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
